import './Cardapio.css';

const Cardapio = () => {
    return (
        <section id='cardapio' className='container__all'>
            <h2 className='h2__container cardapio__h2--color'>Cardápio</h2>
            <div className='cardapio__container secondContainer__all'>
                <div className='p__opacity cardapio__opacity'></div>
                <div className='cardapio__p--container'>
                    <p className='cardapio__p'>O cardápio <span className='cardapio__text--span'>mensal</span> para o lanche coletivo pode ser acessado <a href='./images/infantil.pdf' target='__blank'>aqui.</a></p>
                    <p className='cardapio__p'>O cardápio <span className='cardapio__text--span'>mensal</span> para os kits pode ser acessado <a href='./images/fundamental.pdf' target='__blank'>aqui.</a></p>
                    <span className='cardapio__text--obs'>* (Sucos e frutas serão escolhidos pelas crianças de acordo com as opções disponíveis)</span>
                    <p className='cardapio__p'>O cardápio <span className='cardapio__text--span'>mensal</span> para o almoço pode ser acessado <a href='./images/almoco.pdf' target='__blank'>aqui.</a></p>
                </div>
            </div>
        </section>
    )
}

export default Cardapio;